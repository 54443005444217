*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  padding-inline-start: 1rem;
}

button {
  padding: 0.125rem;
  border-radius: 0;
  border: 1px solid #0ea5e9; /* sky-500 */
  background-color: #e0f2fe; /* sky-100 */
}
button:active,
button:hover {
  background-color: #7dd3fc; /* sky-300 */
}
button:focus-visible {
  outline: 1px solid #8b5cf6; /* violet-500 */
}
